import React from "react"

import { Link, Typography } from "@material-ui/core"

import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"

const BusinessPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Zakelijk` }]}>
      <Helmet>
        <title>Zakelijk - Todocards.nl</title>

        <meta
          name="description"
          content="Wil je een bestelling plaatsen als zakelijke klant of onderwijsinstelling? Wij denken graag me je mee! Bij grotere hoeveelheden kunnen wij mooie kortingen en maatwerk aanbieden."
        />
      </Helmet>

      <Typography variant="h1" gutterBottom>
        Zakelijk
      </Typography>

      <Typography paragraph={true}>
        Wil je een bestelling plaatsen als zakelijke klant of
        onderwijsinstelling? Wij denken graag me je mee! Bij grotere
        hoeveelheden kunnen wij mooie kortingen en maatwerk aanbieden.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Maatwerk
      </Typography>

      <Typography paragraph={true}>
        Wij kunnen onze todocards in vele verschillende uitvoeringen leveren.
        Bijvoorbeeld met jouw eigen logo op de kaartjes. Ook zijn er diverse
        mogelijkheden wat betreft de verpakking en hoeveelheden. Heb je zelf
        ideeën? Wij horen ze graag! Neem{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contact
        </Link>{" "}
        met ons op om een offerte aan te vragen.
      </Typography>

      <Typography variant="h2" gutterBottom>
        BTW
      </Typography>

      <Typography paragraph={true}>
        Alle genoemde prijzen op onze website zijn inclusief 21% BTW. Zakelijke
        klanten uit Nederland kunnen deze BTW terugvragen bij hun volgende
        aangifte.
      </Typography>

      <Typography>
        Ben je een zakelijke klant uit een ander EU-land? Dan is het mogelijk om
        de BTW te verleggen. Je hoeft dan geen BTW te betalen. Neem{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contact
        </Link>{" "}
        met ons op om gebruik te maken van deze mogelijkheid.
      </Typography>
    </MainShell>
  )
}

export default BusinessPage
